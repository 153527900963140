:root {
  --gutter-width-desktop: 24px;
  --gutter-width-smallDesktop: 24px;
  --gutter-width-tablet: 16px;
  --gutter-width-mobile: 8px;

  --outer-margin-desktop: 48px;
  --outer-margin-smallDesktop: 48px;
  --outer-margin-tablet: 32px;
  --outer-margin-mobile: 16px;

  --gutter-compensation-mobile: calc((var(--gutter-width-mobile) * 0.5) * -1);
  --gutter-compensation-tablet: calc((var(--gutter-width-tablet) * 0.5) * -1);
  --gutter-compensation-smallDesktop: calc(
    (var(--gutter-width-smallDesktop) * 0.5) * -1
  );
  --gutter-compensation-desktop: calc((var(--gutter-width-desktop) * 0.5) * -1);

  --half-gutter-width-mobile: calc((var(--gutter-width-mobile) * 0.5));
  --half-gutter-width-tablet: calc((var(--gutter-width-tablet) * 0.5));
  --half-gutter-width-smallDesktop: calc((var(--gutter-width-desktop) * 0.5));
  --half-gutter-width-desktop: calc((var(--gutter-width-desktop) * 0.5));

  --mobile-min: 30;
  --tablet-min: 601;
  --smallDesktop-min: 769;
  --desktop-min: 1024;

  --screen-mobile-min: var(--mobile-min) px;
  --screen-tablet-min: var(--tablet-min) px;
  --screen-smallDesktop-min: var(--smallDesktop-min) px;
  --screen-desktop-min: var(--desktop-min) px;
  --container-mobile: calc(var(--mobile-min) + var(--gutter-width-mobile));
  --container-tablet: calc(var(--tablet-min) + var(--gutter-width-tablet));
  --container-smallDesktop: calc(
    var(--smallDesktop-min) + var(--gutter-width-smallDesktop)
  );
  --container-desktop: calc(var(--desktop-min) + var(--gutter-width-desktop));
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: var(--outer-margin-mobile);
  padding-left: var(--outer-margin-mobile);
}

.container-fluid.full-width {
  width: 100%;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: var(--gutter-compensation-mobile);
  margin-left: var(--gutter-compensation-mobile);
}

.row.full-width {
  width: 100%;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.container {
  width: var(--container-mobile);
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-offset-0,
.col-offset-1,
.col-offset-2,
.col-offset-3,
.col-offset-4,
.col-offset-5,
.col-offset-6,
.col-offset-7,
.col-offset-8,
.col-offset-9,
.col-offset-10,
.col-offset-11,
.col-offset-12,
.col-mobile,
.col-mobile-1,
.col-mobile-2,
.col-mobile-3,
.col-mobile-4,
.col-mobile-5,
.col-mobile-6,
.col-mobile-7,
.col-mobile-8,
.col-mobile-9,
.col-mobile-10,
.col-mobile-11,
.col-mobile-12,
.col-mobile-offset-0,
.col-mobile-offset-1,
.col-mobile-offset-2,
.col-mobile-offset-3,
.col-mobile-offset-4,
.col-mobile-offset-5,
.col-mobile-offset-6,
.col-mobile-offset-7,
.col-mobile-offset-8,
.col-mobile-offset-9,
.col-mobile-offset-10,
.col-mobile-offset-11,
.col-mobile-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width-mobile);
  padding-left: var(--half-gutter-width-mobile);
}

.col,
.col-mobile {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1,
.col-mobile-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2,
.col-mobile-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3,
.col-mobile-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4,
.col-mobile-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5,
.col-mobile-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-6,
.col-mobile-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-7,
.col-mobile-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8,
.col-mobile-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9,
.col-mobile-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10,
.col-mobile-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-11,
.col-mobile-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-12,
.col-mobile-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-offset-0,
.col-mobile-offset-0 {
  margin-left: 0;
}

.col-offset-1,
.col-mobile-offset-1 {
  margin-left: 8.33333333%;
}

.col-offset-2,
.col-mobile-offset-2 {
  margin-left: 16.66666667%;
}

.col-offset-3,
.col-mobile-offset-3 {
  margin-left: 25%;
}

.col-offset-4,
.col-mobile-offset-4 {
  margin-left: 33.33333333%;
}

.col-offset-5,
.col-mobile-offset-5 {
  margin-left: 41.66666667%;
}

.col-offset-6,
.col-mobile-offset-6 {
  margin-left: 50%;
}

.col-offset-7,
.col-mobile-offset-7 {
  margin-left: 58.33333333%;
}

.col-offset-8,
.col-mobile-offset-8 {
  margin-left: 66.66666667%;
}

.col-offset-9,
.col-mobile-offset-9 {
  margin-left: 75%;
}

.col-offset-10,
.col-mobile-offset-10 {
  margin-left: 83.33333333%;
}

.col-offset-11,
.col-mobile-offset-11 {
  margin-left: 91.66666667%;
}

.start,
.start-mobile {
  justify-content: flex-start;
  text-align: start;
}

.center,
.center-mobile {
  justify-content: center;
  text-align: center;
}

.end,
.end-mobile {
  justify-content: flex-end;
  text-align: end;
}

.top,
.top-mobile {
  align-items: flex-start;
}

.middle,
.middle-mobile {
  align-items: center;
}

.bottom,
.bottom-mobile {
  align-items: flex-end;
}

.around,
.around-mobile {
  justify-content: space-around;
}

.between,
.between-mobile {
  justify-content: space-between;
}

.first,
.first-mobile {
  order: -1;
}

.last,
.last-mobile {
  order: 1;
}

@media (min-width: 601px) {
  .container {
    width: var(--container-tablet);
  }

  .container-fluid {
    padding-right: var(--outer-margin-tablet);
    padding-left: var(--outer-margin-tablet);
  }

  .row {
    margin-right: var(--gutter-compensation-tablet);
    margin-left: var(--gutter-compensation-tablet);
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-offset-0,
  .col-offset-1,
  .col-offset-2,
  .col-offset-3,
  .col-offset-4,
  .col-offset-5,
  .col-offset-6,
  .col-offset-7,
  .col-offset-8,
  .col-offset-9,
  .col-offset-10,
  .col-offset-11,
  .col-offset-12,
  .col-tablet,
  .col-tablet-1,
  .col-tablet-2,
  .col-tablet-3,
  .col-tablet-4,
  .col-tablet-5,
  .col-tablet-6,
  .col-tablet-7,
  .col-tablet-8,
  .col-tablet-9,
  .col-tablet-10,
  .col-tablet-11,
  .col-tablet-12,
  .col-tablet-offset-0,
  .col-tablet-offset-1,
  .col-tablet-offset-2,
  .col-tablet-offset-3,
  .col-tablet-offset-4,
  .col-tablet-offset-5,
  .col-tablet-offset-6,
  .col-tablet-offset-7,
  .col-tablet-offset-8,
  .col-tablet-offset-9,
  .col-tablet-offset-10,
  .col-tablet-offset-11,
  .col-tablet-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width-tablet);
    padding-left: var(--half-gutter-width-tablet);
  }

  .col,
  .col-tablet {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1,
  .col-tablet-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-2,
  .col-tablet-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-3,
  .col-tablet-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-4,
  .col-tablet-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-5,
  .col-tablet-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-6,
  .col-tablet-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-7,
  .col-tablet-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-8,
  .col-tablet-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-9,
  .col-tablet-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-10,
  .col-tablet-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-11,
  .col-tablet-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-12,
  .col-tablet-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-tablet-offset-0 {
    margin-left: 0;
  }

  .col-tablet-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-tablet-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-tablet-offset-3 {
    margin-left: 25%;
  }

  .col-tablet-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-tablet-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-tablet-offset-6 {
    margin-left: 50%;
  }

  .col-tablet-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-tablet-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-tablet-offset-9 {
    margin-left: 75%;
  }

  .col-tablet-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-tablet-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-tablet {
    justify-content: flex-start;
    text-align: start;
  }

  .center-tablet {
    justify-content: center;
    text-align: center;
  }

  .end-tablet {
    justify-content: flex-end;
    text-align: end;
  }

  .top-tablet {
    align-items: flex-start;
  }

  .middle-tablet {
    align-items: center;
  }

  .bottom-tablet {
    align-items: flex-end;
  }

  .around-tablet {
    justify-content: space-around;
  }

  .between-tablet {
    justify-content: space-between;
  }

  .first-tablet {
    order: -1;
  }

  .last-tablet {
    order: 1;
  }
}

@media (min-width: 769px) {
  .container {
    width: var(--container-smallDesktop);
  }

  .container-fluid {
    padding-right: var(--outer-margin-smallDesktop);
    padding-left: var(--outer-margin-smallDesktop);
  }

  .row {
    margin-right: var(--gutter-compensation-smallDesktop);
    margin-left: var(--gutter-compensation-smallDesktop);
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-offset-0,
  .col-offset-1,
  .col-offset-2,
  .col-offset-3,
  .col-offset-4,
  .col-offset-5,
  .col-offset-6,
  .col-offset-7,
  .col-offset-8,
  .col-offset-9,
  .col-offset-10,
  .col-offset-11,
  .col-offset-12,
  .col-smallDesktop,
  .col-smallDesktop-1,
  .col-smallDesktop-2,
  .col-smallDesktop-3,
  .col-smallDesktop-4,
  .col-smallDesktop-5,
  .col-smallDesktop-6,
  .col-smallDesktop-7,
  .col-smallDesktop-8,
  .col-smallDesktop-9,
  .col-smallDesktop-10,
  .col-smallDesktop-11,
  .col-smallDesktop-12,
  .col-smallDesktop-offset-0,
  .col-smallDesktop-offset-1,
  .col-smallDesktop-offset-2,
  .col-smallDesktop-offset-3,
  .col-smallDesktop-offset-4,
  .col-smallDesktop-offset-5,
  .col-smallDesktop-offset-6,
  .col-smallDesktop-offset-7,
  .col-smallDesktop-offset-8,
  .col-smallDesktop-offset-9,
  .col-smallDesktop-offset-10,
  .col-smallDesktop-offset-11,
  .col-smallDesktop-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width-smallDesktop);
    padding-left: var(--half-gutter-width-smallDesktop);
  }

  .col,
  .col-smallDesktop {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1,
  .col-smallDesktop-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-2,
  .col-smallDesktop-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-3,
  .col-smallDesktop-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-4,
  .col-smallDesktop-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-5,
  .col-smallDesktop-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-6,
  .col-smallDesktop-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-7,
  .col-smallDesktop-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-8,
  .col-smallDesktop-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-9,
  .col-smallDesktop-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-10,
  .col-smallDesktop-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-11,
  .col-smallDesktop-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-12,
  .col-smallDesktop-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-smallDesktop-offset-0 {
    margin-left: 0;
  }

  .col-smallDesktop-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-smallDesktop-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-smallDesktop-offset-3 {
    margin-left: 25%;
  }

  .col-smallDesktop-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-smallDesktop-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-smallDesktop-offset-6 {
    margin-left: 50%;
  }

  .col-smallDesktop-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-smallDesktop-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-smallDesktop-offset-9 {
    margin-left: 75%;
  }

  .col-smallDesktop-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-smallDesktop-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-smallDesktop {
    justify-content: flex-start;
    text-align: start;
  }

  .center-smallDesktop {
    justify-content: center;
    text-align: center;
  }

  .end-smallDesktop {
    justify-content: flex-end;
    text-align: end;
  }

  .top-smallDesktop {
    align-items: flex-start;
  }

  .middle-smallDesktop {
    align-items: center;
  }

  .bottom-smallDesktop {
    align-items: flex-end;
  }

  .around-smallDesktop {
    justify-content: space-around;
  }

  .between-smallDesktop {
    justify-content: space-between;
  }

  .first-smallDesktop {
    order: -1;
  }

  .last-smallDesktop {
    order: 1;
  }
}

@media (min-width: 1024px) {
  .container {
    width: var(--container-desktop);
  }

  .container-fluid {
    padding-right: var(--outer-margin-desktop);
    padding-left: var(--outer-margin-desktop);
  }

  .row {
    margin-right: var(--gutter-compensation-desktop);
    margin-left: var(--gutter-compensation-desktop);
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-offset-0,
  .col-offset-1,
  .col-offset-2,
  .col-offset-3,
  .col-offset-4,
  .col-offset-5,
  .col-offset-6,
  .col-offset-7,
  .col-offset-8,
  .col-offset-9,
  .col-offset-10,
  .col-offset-11,
  .col-offset-12,
  .col-desktop,
  .col-desktop-1,
  .col-desktop-2,
  .col-desktop-3,
  .col-desktop-4,
  .col-desktop-5,
  .col-desktop-6,
  .col-desktop-7,
  .col-desktop-8,
  .col-desktop-9,
  .col-desktop-10,
  .col-desktop-11,
  .col-desktop-12,
  .col-desktop-offset-0,
  .col-desktop-offset-1,
  .col-desktop-offset-2,
  .col-desktop-offset-3,
  .col-desktop-offset-4,
  .col-desktop-offset-5,
  .col-desktop-offset-6,
  .col-desktop-offset-7,
  .col-desktop-offset-8,
  .col-desktop-offset-9,
  .col-desktop-offset-10,
  .col-desktop-offset-11,
  .col-desktop-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width-desktop);
    padding-left: var(--half-gutter-width-desktop);
  }

  .col,
  .col-desktop {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1,
  .col-desktop-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-2,
  .col-desktop-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-3,
  .col-desktop-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-4,
  .col-desktop-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-5,
  .col-desktop-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-6,
  .col-desktop-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-7,
  .col-desktop-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-8,
  .col-desktop-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-9,
  .col-desktop-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-10,
  .col-desktop-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-11,
  .col-desktop-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-12,
  .col-desktop-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-desktop-offset-0 {
    margin-left: 0;
  }

  .col-desktop-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-desktop-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-desktop-offset-3 {
    margin-left: 25%;
  }

  .col-desktop-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-desktop-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-desktop-offset-6 {
    margin-left: 50%;
  }

  .col-desktop-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-desktop-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-desktop-offset-9 {
    margin-left: 75%;
  }

  .col-desktop-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-desktop-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-desktop {
    justify-content: flex-start;
    text-align: start;
  }

  .center-desktop {
    justify-content: center;
    text-align: center;
  }

  .end-desktop {
    justify-content: flex-end;
    text-align: end;
  }

  .top-desktop {
    align-items: flex-start;
  }

  .middle-desktop {
    align-items: center;
  }

  .bottom-desktop {
    align-items: flex-end;
  }

  .around-desktop {
    justify-content: space-around;
  }

  .between-desktop {
    justify-content: space-between;
  }

  .first-desktop {
    order: -1;
  }

  .last-desktop {
    order: 1;
  }
}
