* {
  margin: 0;
  padding: 0;
  font-family: Avenir, sans-serif;
  box-sizing: border-box;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  -webkit-overflow-scrolling: auto;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html,
body,
#root {
  height: 100%;
}
