/* regular */
@font-face {
  font-family: 'Avenir';
  src: url('./1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff');
}

/* italic */
@font-face {
  font-family: 'Avenir';
  font-style: italic;
  src: url('./92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff') format('woff');
}

/* medium */
@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: url('./AvenirNextLTW01-Medium.woff') format('woff');
}

/* bold */
@font-face {
  font-family: 'Avenir';
  font-weight: 600 700;
  src: url('./91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff') format('woff');
}

/* medium */
@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  src: url('./181c847e-cdbc-43d5-ae14-03a81c8953b4.woff') format('woff');
}
